<!--
 * @Date: 2024-08-26 10:20:19
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-12 17:10:49
 * @FilePath: \zyt-mobile-frontend\src\views\ruralFilm\pages\onDemandSuccess.vue
-->
<template>
  <div class="onDemand">
    <!-- <div class="header">
      <van-nav-bar title="点播成功" left-arrow @click-left="onClickLeft" />
    </div> -->
    <div class="module-title">
      <div class="module-title-text">点播影片信息</div>
      <div class="module-title-more"></div>
    </div>
    <div class="content">
      <div class="filmInfo">
        <div class="filmInfo-img">
          <img :src="filmPicture" alt="" />
        </div>
        <div class="filmInfo-content">
          <span>{{ filmTitle }}</span>
          <span>{{ startTime }} - {{ endTime }}</span>
          <span>{{ expectPlayPlace }}({{ showPointName }})</span>
        </div>
      </div>
      <!-- 点播步骤 -->
      <div class="step">
        <div class="step-item">
          <div class="step-item-icon"></div>
          <div class="step-item-text">点播需求提交成功</div>
        </div>
        <div class="step-line step-line-1"></div>
        <div class="step-item">
          <div class="step-item-icon"></div>
          <div class="step-item-text">拼团中</div>
        </div>
        <div class="step-line step-line-2">
          快去邀请好友拼团吧！<a href="javascript:void(0)">邀请好友</a>
        </div>
        <div class="step-item">
          <div class="step-item-icon step-item-icon-2"></div>
          <div class="step-item-text step-item-text-2">拼团成功</div>
        </div>
        <!-- 说明 -->
        <div class="step-explain">
          根据拼团结果及放映点实际情况制定放映计划，以最终接到放映通知为准
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SetPvAndUv } from '@/mixins/PvAndUv'
import { Dialog } from 'vant'

export default {
  name: 'onDemand',
  mixins: [SetPvAndUv],
  data() {
    return {
      filmPicture:
        'http://cdn.zhijiangfilm.com/fb73868751b2ff7e1267cb314c7ebc85c1455.jpg',
      filmTitle: '《我和我的祖国》',
      startTime: '2024年8月26日',
      endTime: '2024年8月26日',
      expectPlayPlace: '四川省成都市武侯区',
      showPointName: '',
    }
  },
  mounted() {
    const {
      filmPicture,
      filmTitle,
      expectPlayStartTime,
      expectPlayEndTime,
      expectPlayPlace,
      showPointName,
    } = this.$route.query
    if (
      filmPicture ||
      filmTitle ||
      expectPlayStartTime ||
      expectPlayEndTime ||
      expectPlayPlace ||
      showPointName
    ) {
      this.filmPicture = filmPicture
      this.filmTitle = filmTitle
      this.startTime = this.moment(expectPlayStartTime).format('YYYY年MM月DD日')
      this.endTime = this.moment(expectPlayEndTime).format('YYYY年MM月DD日')
      this.expectPlayPlace = expectPlayPlace
      this.showPointName = showPointName
    } else {
      Dialog.alert({
        message: '点播信息有误，请重新提交',
      })
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-3)
    },
  },
}
</script>

<style scoped lang="less">
.header {
}

.module-title {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC, serif;
  font-weight: 500;
  color: #363a44;
  line-height: 28px;
  margin: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &-text {
    width: 136px;
    height: 28px;
    padding-left: 8px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      #d3e3ff 120%
    );
    border-radius: 14px;
  }

  &-more {
    margin-right: 12px;
  }
}

.content {
  padding: 12px;

  .filmInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-img {
      width: 100px;
      height: auto;
      overflow: hidden;

      img {
        border-radius: 10px;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    &-content {
      flex: 1;
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC, serif;
        font-weight: 400;
        color: #363a44;
        margin: 5px 0;

        &:first-child {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC, serif;
          font-weight: 500;
          margin: 12px 0;
          color: #363a44;
        }
      }
    }
  }
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;

  &-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-icon {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: @color-primary;

      &-2 {
        background-color: @color-gray;
      }
    }

    &-text {
      margin-left: 6px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC, serif;
      font-weight: 400;
      color: @color-primary;

      &-2 {
        color: @color-gray;
      }
    }
  }

  &-line {
    width: 100%;
    height: 72px;
    margin-left: 6px;
    padding: 12px 17px;
    border-left: 2px dashed @color-primary;
    font-size: @font-size-base;

    &-2 {
      color: @text-color-secondary;
      border-left-color: @color-gray;
    }
  }

  &-explain {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC, serif;
    font-weight: 400;
    color: @color-gray-1;
    padding: 6px 17px;
  }
}

::v-deep .van-nav-bar {
  background-color: transparent;
}
</style>
